import React, { useState, useEffect } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { Modal, Button, Form, Row, Col, Image, Spinner, Card } from 'react-bootstrap';

import axios from 'axios';
import { baseUrl } from '../../BaseUrl';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaArrowRight, FaEdit, FaTrash ,FaCheck} from 'react-icons/fa'; // Import icons
import { BiSelectMultiple } from "react-icons/bi";
import { IoIosAddCircle } from "react-icons/io";
import { SiQuicklook } from "react-icons/si";
// import { FaCheck, FaArrowRight } from 'react-icons/fa'; // Import the tick icon

function CreateCategories() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStoresModalOpen, setIsStoresModalOpen] = useState(false); // New state for stores modal
  const [categoryName, setCategoryName] = useState('');
  const [categoryTitle, setCategoryTitle] = useState('');
  const [metaTittle,setMetaTittle]=useState("")
  const [seoDiscription,setSeoDiscription]=useState("")
  const [categoryImage, setCategoryImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [categories, setCategories] = useState([]);
  const [editingCategory, setEditingCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState([]); // New state for stores
  const [filteredStores, setFilteredStores] = useState([]); // State for filtered stores
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  const [selectedStoreIds, setSelectedStoreIds] = useState([]);
  const [categoriesID,setCategoriesID]=useState()
  const [selectedStoreViewModel,setSelectedStoreViewModel]=useState(false)
  const [selectedStores, setSelectedStores] = useState([]);


  const [newLoading,setNewLoading]=useState(true);

// console.log(selectedStores)
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${baseUrl}api/GetCategories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);
  useEffect(() => {
    const filterStores = () => {
      setFilteredStores(
        stores.filter(store =>
          store.storeName.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    };

    filterStores();
  }, [searchQuery, stores]);
  
  const openModal = (category = null) => {
   
    if (category) {
      setEditingCategory(category);
      setCategoryName(category.categoryName || ''); // Ensure a fallback if categoryName is undefined
      setCategoryImage(category.categoriesicon || null);
      setImagePreview(category.categoriesicon || null)
      setCategoryTitle(category.categoryTittle || '');
      setMetaTittle(category.metaTittle || '');
      setSeoDiscription(category.seoDiscription || '');
    } else {
      setEditingCategory(null);
      setCategoryName('');
      setCategoryTitle('');
      setMetaTittle('');
      setSeoDiscription('');
      setCategoryImage(null);
      setImagePreview(null);
    }
    setIsModalOpen(true);
  };

  const openStoresModal = async (categoryId) => {
    
    setCategoriesID(categoryId)
    setIsStoresModalOpen(true);
    try {
      const response = await axios.get(`${baseUrl}api/createStore/count`);
      setStores(response.data);
      setFilteredStores(response.data); // Initialize filtered stores
      setNewLoading(false)
    } catch (error) {
      console.error('Error fetching stores:', error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeStoresModal = () => {
    setIsStoresModalOpen(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setCategoryImage(file);
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    } else {
      setImagePreview(null);
    }
  };

  const handleSave = async () => {
    setLoading(true); // Start loading
    
    const formData = new FormData();
    formData.append('categoryName', categoryName);
    formData.append('categoryTittle', categoryTitle);
    formData.append('metaTittle', metaTittle);
    formData.append('seoDiscription', seoDiscription);
  
    if (categoryImage) {
      formData.append('categoriesicon', categoryImage); // Append the image file if selected
    }
  
    try {
    
      if (editingCategory) {
        
        // Update category
        await axios.put(`${baseUrl}api/UpdateCategory/${editingCategory.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // This may not be needed since FormData handles it
          },
        });
        toast.success('Category updated successfully!', {
          position: 'top-right',
          autoClose: 3000,
        });
      } else {
        // Create new category
        await axios.post(`${baseUrl}api/CreateCategories`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast.success('Category added successfully!', {
          position: 'top-right',
          autoClose: 3000,
        });
      }
  
      // Fetch updated categories and update the state
      const updatedCategoriesResponse = await axios.get(`${baseUrl}api/GetCategories`);
      setCategories(updatedCategoriesResponse.data);
      closeModal();
    } catch (error) {
      toast.error('Error saving category. Please try again.', {
        position: 'top-right',
        autoClose: 3000,
      });
      console.error('Error saving category:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${baseUrl}api/DeleteCategory/${id}`);
      toast.success('Category deleted successfully!', {
        position: 'top-right',
        autoClose: 3000,
      });

      const updatedCategoriesResponse = await axios.get(`${baseUrl}api/GetCategories`);
      setCategories(updatedCategoriesResponse.data);
    } catch (error) {
      toast.error('Error deleting category. Please try again.', {
        position: 'top-right',
        autoClose: 3000,
      });
      console.error('Error deleting category:', error);
    }
  };

    // console.log(selectedStoreIds,categoriesID)

  const handleStoreClick = (storeId) => {

    setSelectedStoreIds((prevIds) => {
      // If the store ID is already selected, remove it; otherwise, add it
      
      if (prevIds.includes(storeId)) {
        return prevIds.filter((id) => id !== storeId);
      } else {
        return [...prevIds, storeId];
      }
    });
  };
  
  const addstoreid = async () => {
    try {
      // Send selectedStoreIds as JSON
      const response = await axios.post(
        `${baseUrl}api/AddStore/Categories/${categoriesID}`,
        { storeId: selectedStoreIds }, // Send as JSON
        {
          headers: {
            'Content-Type': 'application/json', // Set content type to application/json
          },
        }
      );
      toast.success('Category updated successfully!', {
        position: 'top-right',
        autoClose: 3000,
      });
      
      closeStoresModal()
      setCategoriesID()
      setSelectedStoreIds([])
    } catch (error) {
      toast.error('Error updating category.');
      console.error(error);
    }
  };



  const openviewSelectedStore = async (categoryId) => {
    setCategoriesID(categoryId)
    try {
      const response = await axios.get(`${baseUrl}api/AddStore/Categories/${categoryId}`);
      
      // Check if response.data.storeIds is an array
      if (Array.isArray(response.data.storeIds)) {
        // Flatten the nested arrays into a single array
        const flattenedStoreIds = response.data.storeIds.flat();
        
        // Use a Set to remove duplicates
        const uniqueStoreIds = [...new Set(flattenedStoreIds)];
        
        // Fetch store data for each unique store ID
        const storePromises = uniqueStoreIds.map(id =>
          axios.get(`${baseUrl}api/createStore/${id}`)
        );
        
        // Wait for all API requests to complete
        const storeResponses = await Promise.all(storePromises);
  
        // Extract data from each response
        const storesData = storeResponses.map(response => response.data);
  
        setSelectedStores(storesData);
      } else {
        // If response.data.storeIds is not an array, wrap it in an array
        setSelectedStores([response.data.storeIds]);
      }
  
      setSelectedStoreViewModel(true);
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleDeleteStore = async (storeId) => {
    try {
      // Replace 'categoriesID' with the actual category ID if it's not a variable
      const response = await axios.delete(`${baseUrl}api/deletStoreId/Categories/${categoriesID}/${storeId}`);
      console.log("Deleted store by ID");
        setCategoriesID();
      // Update the local state to reflect the changes
      // const updatedStores = selectedStores.filter(store => store.id !== storeId);
      // setSelectedStores(updatedStores);
    } catch (error) {
      console.log("Error from delete storeId:", error);
    }
  };
  
  


  return (
    <div style={{ height: '100vh', overflow: 'hidden', backgroundColor: '#f3f9f9', borderRadius: 10 }}>
      <Scrollbars
        style={{ width: '100%', height: '100%' }}
        autoHide
        autoHideTimeout={10}
        autoHideDuration={10}
        thumbMinSize={10}
        universal={true}
        renderThumbVertical={({ style, ...props }) =>
          <div {...props} style={{ ...style, backgroundColor: 'black', borderRadius: '4px' }} />
        }
      >
        <Button
          onClick={() => openModal()}
          variant="primary"
          style={{ position: 'absolute', top: 20, right: 20 }}
        >
          Add Category
        </Button>

        {/* Modal */}
        <Modal show={isModalOpen} onHide={closeModal}>
  <Modal.Header closeButton>
    <Modal.Title>{editingCategory ? 'Edit Category' : 'Add Category'}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {loading ? (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    ) : (
      <>
        <Form.Group controlId="categoryName">
          <Form.Label>Category Name</Form.Label>
          <Form.Control
            type="text"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            placeholder="Enter category name"
          />
        </Form.Group>

        <Form.Group controlId="categoryTitle" className="mt-3">
          <Form.Label>Category Title</Form.Label>
          <Form.Control
            type="text"
            value={categoryTitle}
            onChange={(e) => setCategoryTitle(e.target.value)}
            placeholder="Enter category title"
          />
        </Form.Group>

        <Form.Group controlId="metaTittle" className="mt-3">
          <Form.Label>Meta Title</Form.Label>
          <Form.Control
            type="text"
            value={metaTittle}
            onChange={(e) => setMetaTittle(e.target.value)}
            placeholder="Enter meta title"
          />
        </Form.Group>

        <Form.Group controlId="seoDiscription" className="mt-3">
          <Form.Label>SEO Description</Form.Label>
          <Form.Control
            type="text"
            value={seoDiscription}
            onChange={(e) => setSeoDiscription(e.target.value)}
            placeholder="Enter SEO description"
          />
        </Form.Group>

        <Form.Group controlId="categoryImage" className="mt-3">
          <Form.Label>Category Image</Form.Label>
          <Form.Control
            type="file"
            onChange={handleImageChange}
          />
          {imagePreview && (
            <Image src={imagePreview} alt="Category Preview" thumbnail className="mt-2" />
          )}
        </Form.Group>
      </>
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={closeModal} disabled={loading}>
      Close
    </Button>
    <Button variant="primary" onClick={handleSave} disabled={loading}>
      {editingCategory ? 'Update' : 'Save'}
    </Button>
  </Modal.Footer>
</Modal>;

        {/* Stores Modal */}
        <Modal show={isStoresModalOpen} onHide={closeStoresModal} size="lg" scrollable>
  <Modal.Header closeButton>
    <Modal.Title>Stores</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form.Group controlId="storeSearch">
      <Form.Control
        type="text"
        placeholder="Search stores..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="mb-3"
      />
    </Form.Group>
    <Row>
      {newLoading?(
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100%', backgroundColor: '#f3f9f9' }}>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
      ):<div>

      {filteredStores.map((store) => (
        <Col key={store.id} xs={12} md={4} lg={4} className="mb-4">
          <div
            className="d-flex align-items-center border p-3"
            style={{ height: '100%', cursor: 'pointer' }}
            onClick={() => handleStoreClick(store.id)} // Click handler to save store ID
          >
            {store.StoreImage && (
              <Image
                src={store.StoreImage}
                alt={store.storeName}
                style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '15px' }}
              />
            )}
            <div className="flex-grow-1">
              <h5 className="d-flex align-items-center">
                {store.storeName}
                {/* Show tick icon if the store is selected */}
                {selectedStoreIds.includes(store.id) && (
                  <FaCheck style={{ color: 'green', marginLeft: '10px' }} />
                )}
              </h5>
          
            </div>
            {/* <Button
              variant="link"
              onClick={(e) => {
                e.stopPropagation(); // Prevent click event from propagating to the parent div
                console.log('View Store Details');
              }}
            >
              <FaArrowRight />
            </Button> */}
          </div>
        </Col>
      ))}
      </div>
    }
     
    </Row>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={addstoreid}>
      save
    </Button>
  </Modal.Footer>
</Modal>

        {/* Display Categories */}
        <div style={{ margin: '20px' }}>
          <h4>Categories</h4>
          <Row>
            {categories.map((category) => (
              <Col key={category.id} xs={12} md={4} lg={4} className="mb-4">
                <div className="d-flex align-items-center border p-3" style={{ height: '100%' }}>
                  {category.categoriesicon && (
                    <Image 
                      src={category.categoriesicon} 
                      alt={category.categoryName} 
                      style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '10px' }} 
                    />
                  )}
                  <div className="flex-grow-1">
                    <h5 className="mb-1">{category.categoryName}</h5>
                    <p className="mb-0">{category.categoryTittle}</p>
                  </div>
                  <Col>
                
                  <Button variant="warning" onClick={() => openModal(category)} className="me-2">
                    <FaEdit />
                  </Button>
                  <Button variant="danger" onClick={() => handleDelete(category.id)}>
                    <FaTrash />
                  </Button>
                  </Col>
                  <Col>
                  <Button variant="info" onClick={() => openStoresModal(category.id)} className="ms-2">
                    <BiSelectMultiple />
                  </Button>
                  <Button variant="info" onClick={() => openviewSelectedStore(category.id)} className="ms-2">
                  <SiQuicklook />
                  </Button>
                  </Col>
                </div>
              </Col>
            ))}
          </Row>
        </div>
{/* =====================================selectedStore============================= */}
<Modal show={selectedStoreViewModel} onHide={() => setSelectedStoreViewModel(false)} size="lg" scrollable>
    <Modal.Header closeButton>
      <Modal.Title>Selected Store</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Row>
        {selectedStores.length > 0 ? (
          selectedStores.map((store) => (
            <Col key={store.id} sm={12} md={6} lg={4} className="mb-3">
              <Card>
                {store.StoreImage && (
                  <Card.Img
                    variant="top"
                    src={store.StoreImage}
                    alt={store.storeName}
                    style={{ height: '100px', objectFit: "contain" }}
                  />
                )}
                <Card.Body>
                  <Card.Title>{store.storeName}</Card.Title>
                  <Card.Text>
                    {/* Add more store details here if needed */}
                  </Card.Text>
                  <Button 
                    variant="danger" 
                    onClick={() => handleDeleteStore(store.id)}
                  >
                    Delete
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <Col>
            <p>No stores selected</p>
          </Col>
        )}
      </Row>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => setSelectedStoreViewModel(false)}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>



      </Scrollbars>
      <ToastContainer />
    </div>
  );
}

export default CreateCategories;
