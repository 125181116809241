import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseUrl } from '../../BaseUrl';
import Scrollbars from 'react-custom-scrollbars-2';

function DeleteTopCoupon() {
  const [coupon, setCoupon] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  const getTopcoupon = async () => {
    try {
      const response = await axios.get(`${baseUrl}api/SelectTopCoupon`);
      // console.log(response.data);
      setCoupon(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Set loading to false when the data is fetched
    }
  };

  useEffect(() => {
    getTopcoupon();
  }, []);

  const deleteCoupon = async (couponId) => {
    // alert(couponId)
    const confirmDelete = window.confirm("Are you sure you want to delete this coupon?");
    if (!confirmDelete) return; // Return if user cancels delete

    try {
      await axios.delete(`${baseUrl}api/SelectTopCoupon/${couponId}`);
      // Remove the deleted coupon from the state
      alert ("coupon is deleted")
      getTopcoupon();
      // setCoupon((prevCoupons) => prevCoupons.filter(c => c.CouponId !== couponId));
    } catch (error) {
      console.error("Error deleting coupon:", error);
    }
  };

  return (
    <div style={{ height: '100vh', overflow: 'hidden', backgroundColor: "#f3f9f9", borderRadius: 10 }}>
      <Scrollbars
        style={{ width: '100%', height: '100%' }}
        autoHide
        autoHideTimeout={10}
        autoHideDuration={10}
        thumbMinSize={10}
        universal={true}
        renderThumbVertical={({ style, ...props }) =>
          <div {...props} style={{ ...style, backgroundColor: 'black', borderRadius: '4px' }} />
        }
      >
        <div style={{ backgroundColor: "white" }}>
          <div className="container">
            <div
              style={{
                fontFamily: "Poppins",
                fontSize: 20,
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                paddingTop: "2%",
                paddingBottom: "2%",
              }}
            >
              Top Gutschein
            </div>

            {loading ? (
              <div style={{ textAlign: "center", padding: "50px 0" }}>
                <img src='/voucher.gif' style={{ width: 100, height: 100 }} alt="Loading" />
              </div>
            ) : (
              <div className="coupon-container">
                {coupon.map((coupon, index) => (
                  <div
                    className="coupon-card"
                    style={{ cursor: "pointer", transition: "transform 0.3s ease-in-out", marginBottom: "20px" }}
                    key={index}
                  >
                    <div className="image-wrapper" style={{ position: "relative", width: "100%", height: "200px" }}>
                      {/* Lazy loading the coupon image */}
                      <img
                        src={coupon.CouponImage}
                        alt={coupon.StoreName}
                        className="coupon-image"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                        loading="lazy"
                      />
                      {/* Lazy loading the store image */}
                      <img
                        src={coupon.StoreImage}
                        alt={coupon.StoreName}
                        className="store-image"
                        style={{
                          position: "absolute",
                          bottom: "10px",
                          right: "10px",
                          width: "50px",
                          height: "50px",
                          objectFit: "cover",
                          borderRadius: "50%",
                          border: "2px solid white",
                        }}
                        loading="lazy"
                      />
                    </div>

                    {/* Coupon Title */}
                    <div
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 17,
                        fontWeight: "700",
                        paddingBottom: "10px",
                        marginTop: 10,
                        textAlign: "center",
                      }}
                    >
                      {coupon.couponTittle}
                    </div>

                    {/* Delete Button */}
                    <div style={{ textAlign: "center", marginBottom: "10px" }}>
                      <button
                        onClick={() => deleteCoupon(coupon.id)}
                        style={{
                          padding: "8px 16px",
                          backgroundColor: "red",
                          color: "white",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                      >
                        Delete Coupon
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Scrollbars>
    </div>
  );
}

export default DeleteTopCoupon;
