import React, { useEffect, useState } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import Form from "react-bootstrap/Form";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../../BaseUrl';

function AddCoupon() {
  const [fetchAllStoreDate, setFetchAllStoreDate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  const getDataFromDB = async () => {
    setLoading(true);
    try {
      // Fetch all stores in one request without pagination
      const response = await axios.get(`${baseUrl}api/createStore/count`);
      const result = response.data;
      
      // Sort data by create_time in descending order
      const sortedData = result.sort((a, b) => new Date(b.createTime) - new Date(a.createTime));
      setFetchAllStoreDate(sortedData); // Set all stores

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDataFromDB(); // Fetch data when the component mounts
  }, []);

  const handleGridClick = (store) => {
    navigate('/admin/AddCouponMore', { state: store });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredStores = fetchAllStoreDate.filter(store =>
    store.storeName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div style={{ height: '100vh', overflow: 'hidden', backgroundColor: "#f3f9f9", borderRadius: 10 }}>
      <Scrollbars
        style={{ width: '100%', height: '100%' }}
        autoHide
        autoHideTimeout={10}
        autoHideDuration={10}
        thumbMinSize={10}
        universal={true}
        renderThumbVertical={({ style, ...props }) =>
          <div {...props} style={{ ...style, backgroundColor: 'black', borderRadius: '4px' }} />
        }
      >
        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100%', backgroundColor: '#f3f9f9' }}>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div>
            <h2 className='ml-3' style={{ fontFamily: "fantasy" }}>All Stores</h2>
            <Form.Control
              type="text"
              placeholder="Search by store name"
              value={searchTerm}
              onChange={handleSearchChange}
              className="mb-2"
              style={{ width: "50%", borderWidth: '1px solid black', margin: "1%" }}
            />
            <div className="row row-cols-1 row-cols-md-3 g-3">
              {filteredStores.map((store) => (
                <div
                  className='col-12 col-md-4 mb-3'
                  key={store.id}
                  style={{ display: 'flex', justifyContent: 'center', cursor: "pointer" }}
                  onClick={() => handleGridClick(store)}
                >
                  <div style={{
                    width: "100%",
                    backgroundColor: "#ffffff",
                    height: "35vh",
                    borderRadius: 8,
                    border: '1px solid #c7c9c9',
                    margin: '0 10px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.7)' // Add shadow here
                  }}>
                    <div style={{
                      width: "100%",
                      height: '25%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderBottom: '1px solid #c7c9c9'
                    }}>
                      <img src={`${store.StoreImage}`} alt={store.storeName} style={{ width: 90, height: 37 }} loading="lazy" />
                    </div>
                    <div style={{
                      width: "100%",
                      height: '55%',
                      borderBottom: '1px solid #c7c9c9',
                      padding: '10px'
                    }}>
                      <p style={{ fontFamily: "cursive", fontSize: 20, fontWeight: "bold" }}>
                        {store.storeName}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Scrollbars>
      <ToastContainer position="top-right" />
    </div>
  );
}

export default AddCoupon;
