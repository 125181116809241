import React, { useEffect, useState } from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../../BaseUrl';

function CreateShop() {
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(false);
  const [fetchAllStoreData, setFetchAllStoreData] = useState([]);
  const [loadingStores, setLoadingStores] = useState(true);

  const handleDelete = async (store) => {
    setLoading(true);
    try {
      await axios.delete(`${baseUrl}api/createStore/${store.id}`);
      toast.success('Your Store Data Deleted Successfully', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      getDataFromDB(); // Refresh data
    } catch (error) {
      console.error(error);
      toast.error('Your Store Data is not Deleted Successfully', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const getDataFromDB = async () => {
    setLoadingStores(true);
    try {
      const response = await axios.get(`${baseUrl}api/createStore/count`);
      const result = response.data;
      setFetchAllStoreData(result); // Set all fetched store data
      console.log(result)
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoadingStores(false); // Set loading to false once data is fetched
    }
  };

  useEffect(() => {
    getDataFromDB();
  }, []); // Only fetch data once when the component mounts

  const goaddstorepage = (store) => {
    navigate('/admin/AddStore', { state: { data: store } });
  };

  const addstore = () => {
    navigate(`/admin/AddStore`);
  };

  return (
    <div style={{ height: '100vh', overflow: 'hidden', backgroundColor: "#f3f9f9", borderRadius: 10 }}>
      <Scrollbars style={{ width: '100%', height: '100%' }} autoHide>
        <div className="d-flex justify-content-end align-items-end" style={{ padding: "20px" }}>
          <Button type="button" className="btn btn-primary" onClick={addstore}>
            Add Store
          </Button>
        </div>

        {loadingStores ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100%', backgroundColor: '#f3f9f9' }}>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div>
            <h2 className='ml-2' style={{ fontFamily: "fantasy" }}>All Stores</h2>
            <div className="row row-cols-1 row-cols-md-3 g-3">
              {fetchAllStoreData.map((store) => (
                <div className='col-12 col-md-4 mb-3' key={store.id} style={{ display: 'flex', justifyContent: 'center', cursor: "pointer" }}>
                  <div style={{
                    width: "100%",
                    backgroundColor: "#ffffff",
                    height: "35vh",
                    borderRadius: 8,
                    border: '1px solid #c7c9c9',
                    margin: '0 10px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.7)'
                  }}>
                    <div style={{
                      width: "100%",
                      height: '25%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderBottom: '1px solid #c7c9c9'
                    }}>
                      <img 
                        src={store.StoreImage} 
                        alt={store.storeName} 
                        loading="lazy" 
                        style={{ width: 90, height: 37 }} 
                      />
                    </div>
                    <div style={{
                      width: "100%",
                      height: '55%',
                      borderBottom: '1px solid #c7c9c9',
                      padding: '10px'
                    }}>
                      <p style={{ fontFamily: "cursive", fontSize: 20, fontWeight: "bold" }}>
                        {store.storeName}
                      </p>
                      <p style={{ fontFamily: "poppine", fontSize: 20, fontWeight: "bold" }}>
                        {store.storeTitle}
                      </p>
                    </div>
                    <Button variant="danger" onClick={() => handleDelete(store)} disabled={loading} style={{ marginLeft: '10px' }}>
                      {loading ? 'Deleting...' : 'Delete'}
                    </Button>
                    <Button variant="primary" onClick={() => goaddstorepage(store)} disabled={loading}>
                      {loading ? 'Updating...' : 'Update'}
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Scrollbars>
      <ToastContainer />
    </div>
  );
}

export default CreateShop;
